import { observer } from 'mobx-react'
import styled from 'styled-components'
import { generateSearchResultLink } from '../../../utils/post'
import { replaceSpecialChars } from '../../../utils/replaceSpecialChars'
import SearchStore, { ISearchModalList, TermTypes } from '../../stores/SearchStore/SearchStore'
import { SearchLoader } from '../SearchLoader'

const SearchModalContainer = styled.div`
  position: absolute;
  top: 75px;
  left: 20px;
  min-width: 270px;
  width: calc(100% - 40px);
  max-width: 800px;
  height: max-content;
  min-height: 70px;
  background-color: #FFF;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1065px) {
    width: 270px;
    max-width: 270px;
    top: 60px;
    left: 0px;
  }
`

const ArrowUpDiv = styled.div`
  position: absolute;
  top: -13px;
  left: 15px;
  width: 0; 
  height: 0; 
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #FFF;
`

const TermList = styled.ul`
  padding: 0;
  min-width: 100%;
  margin: 0;
`

const TermItem = styled.li`
  list-style: none; 
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 14px 16px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  min-width: 100%;

  &:last-of-type {
    border-bottom: none;
  }

  & > a, span {
    text-decoration: none;
    color: #000;

    & > b {
      color: #00A8E0;
      font-size: 14px;
      font-weight: 700;
    }
  }
`

interface ISearchModal {
  visible: boolean,
  searchStore: SearchStore
}

export const SearchModalWidget = observer((props: ISearchModal) => {
  const { searchStore, visible } = props

  const replaceHelper = (visibleText: string, inputText: string): string => {
    const listOfWords = visibleText.split(' ')
    const listOfTerms = inputText.trim().split(' ')

    listOfWords.map((word, wordIndex) => {
      const replacedWord = replaceSpecialChars(word)
      
      listOfTerms.map(term => {
        if (word.includes(term)) {
          listOfWords[wordIndex] = word.replace(term, `<b>${term}</b>`)
          return
        }

        if (replacedWord.includes(term)) {
          const sliceStart = replacedWord.search(term)
          const sliceEnd = term.length + sliceStart
          const newTerm = word.slice(sliceStart, sliceEnd)
          listOfWords[wordIndex] = word.replace(newTerm, `<b>${newTerm}</b>`)
        }
      })
    })

    return listOfWords.join(' ')
  }

  const renderResults = () => {
    return <TermList data-testid="term-list" id="term-list">
      {searchStore.searchResults.map((term, index) => {
        if (index > 4) return
        const href = generateSearchResultLink(term.type, term.slug, term.category)
        return (
          <TermItem
            key={`${term.name}+${index}-search-result-item`}
            id={`term-item-${index}`}
            onClick={() => { window.location.href = href }}
            dangerouslySetInnerHTML={{
              __html: `<a href=${href}>${replaceHelper(term.name, searchStore.inputText)}</a>`
            }}
          />
        )
      })}
    </TermList>
  }

  return (
    <>
      {(visible && (searchStore.showLoading || searchStore.searchResults.length > 0)) &&
        <SearchModalContainer data-testid="search-modal-container">
          <ArrowUpDiv />
          <>
            {searchStore.showLoading && searchStore.searchResults.length <= 0
              ? <SearchLoader />
              : renderResults()
            }
          </>
        </SearchModalContainer>
      }
    </>
  )
})