export const replaceSpecialChars = (word: string) => {
  word = word.toLowerCase()
  word = word.replaceAll(new RegExp(/[aáàãäâåã]/ig), 'a')
  word = word.replaceAll(new RegExp(/[eéèëêê]/ig), 'e')
  word = word.replaceAll(new RegExp(/[iíìïî]/ig), 'i')
  word = word.replaceAll(new RegExp(/[oóòõöô]/ig), 'o')
  word = word.replaceAll(new RegExp(/[uúùüû]/ig), 'u')
  word = word.replaceAll(new RegExp(/[ç]/ig), 'c')
  word = word.replaceAll(new RegExp(/[ñ]/ig), 'n')
  word = word.replaceAll(new RegExp(/[ÿ]/ig), 'y')
  return word
}