import { useTranslation } from 'next-i18next'
import styled, { css } from 'styled-components'
import { media } from '../../../styles/breakpoints'
import { colors } from '../../../styles/colors'
import { SocialNetworkLinks } from '../../../utils/socialNetworkLinks'
import { InstagramIcon } from '../../atoms/icons/InstagramIcon'
import { LogoIcon } from '../../atoms/icons/LogoIcon'
import { MailIcon } from '../../atoms/icons/MailIcon'
import { YoutubeIcon } from '../../atoms/icons/YoutubeIcon'
import { ILanguageVersionInterface } from '../../templates/LanguageInterface'

const Container = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  z-index: 1;
`

const SocialSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px 40px;

  background-color: #EEE;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  ${media.tabMUp} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 60px 40px 40px;
  }

  ${media.deskGMUp} {
    padding: 60px 125px 40px;
  }

  ${media.deskGG} {
    padding: 60px 165px 40px;
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const PressSession = styled.div<{ isEnVersion: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ isEnVersion }) => isEnVersion ? '0' : '24px 0 30px'};

  background-color: #EEE;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  ${media.deskGMUp} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 60px 125px 40px;
  }

  ${media.deskGG} {
    padding: 60px 165px 40px;
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const SocialMediaDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 0;

  ${media.tabMUp} {
    flex-direction: row;
  }
`

const SocialMediaItem = styled.div`
  display: flex;
  align-content: auto;
  padding-top: 24px;
  position: relative;

  ${media.tabMUp} {
    margin-top: 0px;
    margin-right: 30px;
    padding-top: 0;
    display: flex;
    align-items: center;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:first-of-type {
    padding-top: 0;
  }
`

const BaseLink = styled.a`
  text-decoration: none;
  color: ${colors.gray20};
`

const ItemLink = styled(BaseLink)`
  font-weight: bold;
  align-self: center;
  font-size: 14px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    width: 0;
    height: 1px;
    background: black;
    opacity: 0;
    border-radius: 10px;
    transition: all 200ms ease-in-out;
  }

  &:hover::before {
    ${media.tabMUp} {
      left: 0;
      width: 100%;
      opacity: 1;
    }
  }
`

const LogoLink = styled(BaseLink)`
  margin-bottom: 60px;

  ${media.tabMUp} {
    margin-bottom: 0;
  }

  svg {
    height: 26px;
    width: 122px;

    ${media.deskGMUp} {
      width: 155px;
      height: 36px;
    }
  }
`

const MediaLink = styled(BaseLink)`
  margin-right: 7px;
  display: flex;
  align-items: center;
`

const Language = styled.div<{ centeredDiv: boolean, isEnVersion: boolean }>`
  width: 100%;
  margin-top: 16px;
  margin-bottom: ${({ isEnVersion }) => isEnVersion ? '0' : '40px'};
  padding-bottom: 48px;

  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  span {
    display: block;
    margin-bottom: 12px;

    font-size: 14px;
    color: #666;

    ${media.deskGMUp} {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 14px;
    }
  }

  ${media.deskGMUp} {
    width: auto;
    display: flex;
    align-items: baseline;

    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    
    text-align: left;
    border-bottom: none;
    margin: ${props => props.centeredDiv ? '16px auto 40px' : '16px unset 40px'};
  }
`

const LanguageOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;

  ${media.deskGMUp} {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
`

const bottomBarLanguageOptions = css`
  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0; 
  
    height: 2px;
    background-color: #00A8E0;
  }
`

const LanguageOption = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 130px;
  cursor: pointer;

  ${props => props.active && bottomBarLanguageOptions};

  &:hover {
    ${bottomBarLanguageOptions};
  }

  a {
    font-size: 14px;
    color: #333;
    text-decoration: none;
  }
  
  img {
    width: 16px;
    height: 10px;
    margin-right: 8px;
  }

  ${media.deskGMUp} {
    width: auto;
    display: flex;
  }
`

const Press = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  font-size: 14px;
  line-height: 18px;

  ${media.tabMUp} {
    flex-direction: row;
  }

  span {
    margin: 0 10px;

    ${media.deskGMUp} {
      margin: 0 20px;
    }

    &.responsive {
      display: none;

      ${media.tabMUp} {
        display: unset;
      }
    }
  }

  .press-row {
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    ${media.tabMUp} {
      margin: 0;
    }
  }

  ${media.deskGMUp} {
    width: auto;
    justify-content: flex-start;
  }
`

const PressRow = styled.div`
  display: flex;  
  flex-direction: row;
  margin: 0 auto;

  ${media.tabMUp} {
    margin: 0;
  }
`

const Privacy = styled(ItemLink)`
  font-weight: normal;
  line-height: 20px;
  color: ${colors.gray13};
  margin-bottom: 32px;

  ${media.deskGMUp} {
    margin: 0;
  }
`

export const Footer = (props: ILanguageVersionInterface) => {
  const { t: translate } = useTranslation('footer')
  const isEnVersion = props.language == 'en'
  const mailTo = `mailto:${isEnVersion ? SocialNetworkLinks.EmailEn : SocialNetworkLinks.Email}`
  return (
    <Container>
      <SocialSection>
        <LogoLink href="/" id="logoLink" aria-label={isEnVersion ? 'Go back to home page' : 'Voltar para a página inicial'}><LogoIcon /></LogoLink>
        <SocialMediaDiv>
          <SocialMediaItem id="emailLink">
            <MediaLink href={mailTo}>
              <MailIcon style={{ color: '#EEE' }} />
            </MediaLink>
            <ItemLink href={mailTo}>{isEnVersion ? SocialNetworkLinks.EmailEn : SocialNetworkLinks.Email}</ItemLink>
          </SocialMediaItem>
          <SocialMediaItem id="instagramLink">
            <MediaLink href={isEnVersion ? SocialNetworkLinks.InstagramEn : SocialNetworkLinks.Instagram} aria-label={isEnVersion ? 'Follow us on Instagram' : 'Siga-nos no Instagram'} target="blank"><InstagramIcon /> </MediaLink>
            <ItemLink href={isEnVersion ? SocialNetworkLinks.InstagramEn : SocialNetworkLinks.Instagram} target="blank" aria-label={isEnVersion ? 'Follow us on Instagram' : 'Siga-nos no Instagram'}>@persono</ItemLink>
          </SocialMediaItem>
          {
            !isEnVersion
              ? <SocialMediaItem id="youtubeLink">
                <MediaLink href={SocialNetworkLinks.YouTube} target="blank" aria-label={isEnVersion ? 'Follow us on YouTube' : 'Siga-nos no YouTube'}><YoutubeIcon /> </MediaLink>
                <ItemLink href={SocialNetworkLinks.YouTube} target="blank" aria-label={isEnVersion ? 'Follow us on YouTube' : 'Siga-nos no YouTube'}>{translate('persono-channel')}</ItemLink>
              </SocialMediaItem>
              : <></>
          }
        </SocialMediaDiv>
      </SocialSection>
      <PressSession isEnVersion={isEnVersion}>
        <Language centeredDiv={isEnVersion} isEnVersion={isEnVersion}>
          <span>{translate('site-language')}</span>
          <LanguageOptions>
            <LanguageOption active={isEnVersion}>
              <img src="/images/en-flag.png" alt="English Version" />
              <a href="https://persono.com">English</a>
            </LanguageOption>
            <LanguageOption active={!isEnVersion}>
              <img src="/images/ptBr-flag.png" alt="Versão Português" />
              <a href="https://persono.com.br">Português</a>
            </LanguageOption>
          </LanguageOptions>
        </Language>
        {
          !isEnVersion
            ? <Press>
              <PressRow>
                <Privacy href="/quem-somos"> {translate('about-us')} </Privacy>
                <span className="responsive"> • </span>
              </PressRow>
              <PressRow>
                <Privacy href="/tecnologia"> {translate('technology')} </Privacy>
                <span> • </span>
                <Privacy href="/termos-de-uso"> {translate('privacy-policy')} </Privacy>
                <span className="responsive"> • </span>
              </PressRow>
              <PressRow>
                <Privacy href="/imprensa"> {translate('press-release')} </Privacy>
                <span> • </span>
                <Privacy href="/faq"> {translate('faq')} </Privacy>
              </PressRow>
            </Press>
            : <></>
        }
      </PressSession>
    </Container>
  )
}