import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { ButtonLink } from '../../atoms/Button'
import { LogoIcon } from '../../atoms/icons/LogoIcon'
import DropdownMenu from '../../atoms/NavDropdown'
import { NavItem } from '../../atoms/NavItem'
import { NavbarProps } from '../../organisms/Header'
import { DesktopSearchTermsInput } from '../DesktopSearchTermsInput'

const NavbarComponent = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  min-height: 50px;
  background-color: white;
  z-index: 90;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  height: 96px;
  padding: 0 40px;
`

const HomeLink = styled.a`
  height: 36px;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
`

const NavbarDesktop = (props: NavbarProps) => {
  const router = useRouter()
  const { t: translate } = useTranslation('header')

  return (
    <Wrapper id="wrapper" data-testid="navbar-desktop-wrapper">
      <Content id="content">
        <HomeLink href="/" id="home-link">
          <LogoIcon aria-label={props.isEnVersion ? 'Go back to Home page' : 'Voltar para a página inicial'} />
        </HomeLink>

        <nav>
          <NavbarComponent id="navbar-component">
            {props.isEnVersion
              ? <></>
              : <DropdownMenu
                title={translate('sleep')}
                dropdownItems={props.dropdownItems}
              />
            }

            {props.menuItems.map(item => (
              <NavItem
                href={item.path}
                isActive={item.path == '/' ? router.asPath == '/' : router.asPath.includes(item.path)}
                key={item.path}
                isEnVersion={false}
              >
                {translate(item.key)}
              </NavItem>
            ))}

          </NavbarComponent>
        </nav>

        {!props.isEnVersion
          ? <DesktopSearchTermsInput searchStore={props.searchStore} />
          : <ButtonLink href="/buy">
            {translate('buy-here')}
          </ButtonLink>
        }
      </Content>
    </Wrapper>
  )
}

export default NavbarDesktop