import { SVG } from '../Svg'

export const MailIcon = props => (
  <SVG title="Mail Icon" width="24" height="20" viewBox="0 0 24 20" fill="none" {...props}>
    <path d="M1 1H23V19H1V1Z" stroke="#333333" strokeWidth="2" />
    <mask id="path-2-inside-1" fill="white">
      <path d="M0.223251 0.254776L10.5768 12.1337C11.3443 13.0094 12.7407 12.999 13.4919 12.1417L23.8384 0.350382" />
    </mask>
    <path d="M10.5768 12.1337L9.06914 13.4478L9.07275 13.452L10.5768 12.1337ZM13.4919 12.1417L11.9886 10.8226L11.9877 10.8236L13.4919 12.1417ZM-1.28445 1.56887L9.06915 13.4478L12.0845 10.8197L1.73095 -1.05932L-1.28445 1.56887ZM9.07275 13.452C10.6383 15.2382 13.4496 15.2247 14.9961 13.4598L11.9877 10.8236C12.0031 10.8061 12.0203 10.7954 12.0313 10.7905C12.0403 10.7867 12.0426 10.7876 12.0393 10.7876C12.0363 10.7876 12.0385 10.7867 12.0468 10.7903C12.0575 10.795 12.0709 10.8041 12.0809 10.8155L9.07275 13.452ZM14.9952 13.4608L25.3417 1.66949L22.3351 -0.968724L11.9886 10.8226L14.9952 13.4608Z" fill="#333333" mask="url(#path-2-inside-1)" />
    <mask id="path-4-inside-2" fill="white">
      <path d="M0.578812 19.3414L8.51165 10.1592L0.578812 19.3414Z" />
    </mask>
    <path d="M2.09223 20.6489L10.0251 11.4667L6.99824 8.85174L-0.934606 18.0339L2.09223 20.6489Z" fill="#333333" mask="url(#path-4-inside-2)" />
    <mask id="path-6-inside-3" fill="white">
      <path d="M23.3302 19.2355L15.6113 10.2866L23.3302 19.2355Z" />
    </mask>
    <path d="M24.8447 17.9292L17.1257 8.98026L14.0968 11.5929L21.8158 20.5418L24.8447 17.9292Z" fill="#333333" mask="url(#path-6-inside-3)" />
  </SVG>
)