import styled, { css } from 'styled-components'
import { media } from '../../../styles/breakpoints'
import { colors } from '../../../styles/colors'

const Item = styled.li<{ isEnVersion?: boolean }>`
  position: relative;
  height: 32px;
  margin-right: 16px;
  list-style: none;
  overflow: visible;
  min-width: max-content;
  
  @media only screen and (min-width: 1124px) {
    margin-top: -5px;
    margin-left: 16px;
    overflow: hidden;
  }
`

const Link = styled.a<{ isActive: boolean }>`
  position: relative;
  display: inline-block;
  height: 100%;
  font-size: 12px;
  line-height: 32px;
  color: ${colors.gray13};
  text-transform: uppercase;
  text-decoration: none;
  
  &::before {
    content: "";
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
    width: ${props => props.isActive ? '100%' : '10px'};
    opacity: ${props => props.isActive ? 1 : 0};
    height: 3px;
    background: ${colors.irisBlue};
    border-radius: 10px;
    transition: all 200ms ease-in-out;
  }

  ${props => !props.isActive && css`
    &:hover::before {
      opacity: 1;
      transform: scaleX(9.5);
      transition: all 200ms ease-in-out;
    }
  `};

  ${media.tabMUp} {
    font-size: 14px;
  }
`

const DropdownLink = styled(Link)`
  &::before {
    margin: 0;
  }

  ${props => !props.isActive && css`
    &:hover::before {
      opacity: 1;
      transform: scaleX(40);
      transition: all 200ms ease-in-out;
    }
  `};
`

interface MenuItemProps {
  href: string,
  children: string,
  isActive: boolean,
  isEnVersion?: boolean
}

export const NavItem = (props: MenuItemProps) => (
  <Item isEnVersion={props.isEnVersion}>
    <Link href={props.href} isActive={props.isActive}>
      {props.children}
    </Link>
  </Item>
)

export const DropdownItem = (props: MenuItemProps) => (
  <Item isEnVersion={props.isEnVersion}>
    <DropdownLink href={props.href} isActive={props.isActive}>
      {props.children}
    </DropdownLink>
  </Item>
)