import { MutableRefObject, useState } from 'react'
import styled from 'styled-components'
import { CloseIcon } from '../../atoms/icons/CloseIcon'
import { SearchIcon } from '../../atoms/icons/SearchIcon'
import { SearchTermsInput } from '../../atoms/SearchInput'
import SearchStore from '../../stores/SearchStore/SearchStore'

const SearchContainer = styled.div<{ expanded: boolean }>`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 25px;
  width: ${({ expanded }) => expanded ? '325px' : '10%'};
  max-width: 325px;
  height: 52px;
  transition: all ease-in-out 300ms;

  @media only screen and (min-width: 1300px) {
    min-width: 225px;
    max-width: 325px;
    width: ${({ expanded }) => expanded ? '325px' : '225px'};
    margin-left: ${({ expanded }) => expanded ? '-100px' : '0px'};
  }
`

const IconWrapper = styled.div`
  width: 40px;
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  z-index: 99;

  & > * {
    width: 25px;
    height: 25px;
  }
`

interface IDesktopSearchTermsInput {
  searchStore: SearchStore,
}

export const DesktopSearchTermsInput = (props: IDesktopSearchTermsInput) => {
  const [searchExpanded, setSearchExpanded] = useState(false)
  const { searchStore } = props
  const toggleSearchExpanded = () => {
    setSearchExpanded(!searchExpanded)
    try {
      setTimeout(() => {
        document.getElementById('header-search-input').focus()
      }, 200)
    } catch (e) {
      if(process.env.NODE_ENV !== 'production') {
        console.log(e)
      }
    }
  }

  return (
    <SearchContainer expanded={searchExpanded} data-testid="desktop-search-terms-input">
      <SearchTermsInput
        searchStore={searchStore}
        expanded={searchExpanded}
        disabled={!searchExpanded}
      />

      <IconWrapper onClick={() => toggleSearchExpanded()} data-testid="search-icon-wrapper">
        {searchExpanded
          ? <CloseIcon title="Fechar" data-testid="search-input-icon-close" />
          : <SearchIcon title="Procurar" data-testid="search-input-icon-search" />
        }
      </IconWrapper>
    </SearchContainer>
  )
}