import { observer } from 'mobx-react'
import styled from 'styled-components'
import SearchStore from '../../stores/SearchStore/SearchStore'
import { CloseIcon } from '../icons/CloseIcon'
import { SearchIcon } from '../icons/SearchIcon'
import { SearchModalWidget } from '../SearchModal'

const Input = styled.input<{ expanded: boolean }>`
  width: 100%;
  height: 100%;
  border: none;
  background-color: #F8F8F8;
  outline: none;
  margin: 0 15px;
  font-size: 16px;
  line-height: 120%;
  color: #000;
  position: relative;
  left: ${({ expanded }) => expanded ? '0px' : '30%'};
  filter: ${({ expanded }) => expanded ? 'opacity(1)' : 'opacity(0)'};
  -webkit-appearance: none;
  appearance: none;

  @media only screen and (min-width: 1065px) {
    transition: all ease-in-out 200ms 100ms;
    transform: ${({ expanded }) => expanded ? 'scale(1,1)' : 'scale(0.5,1) '};
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
  }

  &:focus {
    border: none;
    outline: none;
  }
`

const CloseIconContainer = styled.div<{ expanded: boolean, hasText: boolean }>`
  min-height: 20px;
  min-width: 20px;
  border-radius: 50px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 150ms 100ms;
  filter: ${({ expanded, hasText }) => expanded ? (hasText ? 'opacity(1)' : 'opacity(0)') : 'opacity(0)'};
  transform: ${({ expanded }) => expanded ? 'scale(1,1)' : 'scale(0.5,1) '};
  z-index: 1;

  & > * {
    filter: invert(100%);
    min-width: 15px;
    max-width: 15px;
    min-height: 15px;
    max-height: 15px;
  }
`

const SearchIconContainer = styled.div<{ expanded: boolean }>`
  min-height: 22px;
  min-width: 22px;
  display: flex;
  cursor: pointer;
  transition: all ease-in-out 100ms 50ms;
  filter: ${({ expanded }) => expanded ? 'opacity(1)' : 'opacity(0)'};
  transform: ${({ expanded }) => expanded ? 'scale(1,1)' : 'scale(0,0)'};

  & > * {
    max-width: 22px;
    max-height: 22px;
  }
`

const MainContainer = styled.div<{ expanded: boolean }>`
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #F8F8F8;
  padding: 0 20px;
  border-radius: 8px;
  border: 2px solid #ccc;
  margin-right: 20px;
  z-index: 1;

  @media only screen and (min-width: 1065px) {
    transition: all ease-in-out 200ms 100ms;
    transform: ${({ expanded }) => expanded ? 'scale(1,1)' : 'scale(0.5,1) '};
    position: relative;
    left: ${({ expanded }) => expanded ? '0px' : '30%'};
    filter: ${({ expanded }) => expanded ? 'opacity(1)' : 'opacity(0)'};
  }
`

interface ISearchTermsInputProps {
  searchStore: SearchStore
  expanded: boolean,
  disabled: boolean,
}

export const SearchTermsInput = observer((props: ISearchTermsInputProps) => {
  const { searchStore } = props
  
  return (
    <MainContainer expanded={props.expanded} data-testid="main-input-container">
      <SearchIconContainer onClick={() => searchStore.goToSearchPage()} expanded={props.expanded} data-testid="search-icon-container">
        <SearchIcon />
      </SearchIconContainer>

      <Input
        type="search"
        value={searchStore.inputText}
        maxLength={75}
        expanded={props.expanded}
        onChange={event => searchStore.setInputText(event.target.value.toLowerCase())}
        onKeyUp={event => searchStore.onKeyUp(event.code)}
        disabled={props.disabled}
        data-testid="search-input"
        id="header-search-input"
      />

      <SearchModalWidget
        visible={searchStore.inputText.length > 0 && props.expanded}
        searchStore={searchStore}
      />

      {!searchStore.isInputTextEmpty() &&
        <CloseIconContainer
          onClick={() => searchStore.clearInputText()}
          expanded={props.expanded}
          data-testid="close-icon-container"
          hasText={!searchStore.isInputTextEmpty()}
        >
          <CloseIcon />
        </CloseIconContainer>
      }
    </MainContainer>
  )
})