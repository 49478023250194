import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import styled from 'styled-components'
import { CloseIcon } from '../../../atoms/icons/CloseIcon'
import { DropdownIcon } from '../../../atoms/icons/DropdownIcon'
import { HamburgerMenuIcon } from '../../../atoms/icons/HamburgerMenuIcon'
import { SearchIcon } from '../../../atoms/icons/SearchIcon'
import { SmallLogoIcon } from '../../../atoms/icons/SmallLogoIcon'
import { SearchTermsInput } from '../../../atoms/SearchInput'
import { NavbarProps } from '../../../organisms/Header'

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 66px;
  min-height: 66px;
  padding: 0 20px;
  background-color: white;
  z-index: 90;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  @media only screen and (min-width: 1065px) {
    height: 96px;
    padding: 0 40px;
  }
`

const HomeLink = styled.a`
  height: 26px;

  @media only screen and (min-width: 1065px) {
    height: 46px;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

const MenuButton = styled.button`
  border: none;
  background-color: #000;
  background-color: transparent;
`

const ItemMenu = styled.a`
  font: 14px 'Lato' sans-serif;
  height: 76px;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 16px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  text-decoration: none;
  width: 100%;
`

const DropdownMobileTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: 14px Lato, sans-serif;
  height: 76px;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 16px;
  padding-right: 35px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  color: #000;
  text-decoration: none;
  width: 100%;
`

const IconWrapper = styled.div<{ reverse: boolean }>`
  transform: scale(1.3, 1.3) ${({ reverse }) => reverse ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 200ms ease-in-out;
`

const DropdownMobile = styled.div`
  background: rgba(204, 204, 204, 0.1);
  display: flex;
  flex-direction: column;
  font: 14px Lato, sans-serif;
  text-transform: uppercase;
  padding-left: 30px;
  padding-top: 30px;
  height: max-content;
  transition: all 100ms ease-in-out;
  
  a {
    margin-bottom: 30px;
    text-decoration: none;
    color: #000;
  }
`

const ButtonMenu = styled.a<{ isEnVersion?: boolean, dropdownVisible: boolean }>`
  font: Lato, sans-serif;
  background-color: #00A8E0;
  border-radius: 30px;
  border: 0px solid transparent;
  text-align: center;
  text-decoration: none;
  color: white;
  margin: 30px 20px;
  min-height: 50px;
  height: 76px;
  max-height: 76px;
  padding-top: 16px;
  font-size: 14px;
  font-weight: bold;
`

const SearchIconWrapper = styled.div`
  width: 40px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  z-index: 99;

  & > * {
    width: 25px;
    height: 25px;
  }
`

const SearchWrapper = styled.div`
  min-width: 100%;
  height: 80px;
  background-color: #FFF;
  padding: 16px;
  overflow: visible;
  z-index: 90;
`

const BaseStyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: #FFF;
  margin-top: 65px;
  width: 100%;
  top: 0;
  left: 0;
  text-align: left;
  position: fixed;
  transition: transform 200ms ease-in-out;
  overflow-x: hidden;
  z-index: 70;
  border-top: 1px solid #CCC;
`

const SearchStyledMenu = styled(BaseStyledMenu) <{ isSearchExpanded: boolean }>`
  height: 80px;
  transform: ${({ isSearchExpanded }) => isSearchExpanded ? 'translateY(0)' : 'translateY(-200%)'};
  overflow: visible;
`

const StyledMenu = styled(BaseStyledMenu) <{ isOpen: boolean, isEnVersion: boolean, dropdownVisible: boolean }>`
  height: ${({ isEnVersion, dropdownVisible }) => isEnVersion ? '300px' : (dropdownVisible ? '99vh' : '300px')};
  transform: ${({ isOpen }) => isOpen ? 'translateY(0)' : 'translateY(-200%)'};  
  overflow-y: ${({ dropdownVisible }) => dropdownVisible ? 'scroll' : 'hidden'};
`

const BaseBackgroundWithOpacity = styled.div`
  margin: -8px;
  position: fixed;
  right: 0;
  min-width: 110%;
  overflow: hidden;
  background-color: #000;
  -webkit-transition: opacity 350ms ease-in-out;
  -moz-transition: opacity 350ms ease-in-out;
  -ms-transition: opacity 350ms ease-in-out;
  -o-transition: opacity 350ms ease-in-out;
  transition: opacity 150ms 200ms ease-in-out;
`

const BackgroundWithOpacity = styled(BaseBackgroundWithOpacity) <{ isShowingMenu: boolean, dropdownVisible: boolean, isEnVersion: boolean }>`
  top: ${({ isEnVersion }) => isEnVersion ? '373px' : '371px'};
  min-height: ${({ isShowingMenu, dropdownVisible }) => isShowingMenu ? (dropdownVisible ? '0' : '100vh') : '0'};
  max-height: ${({ isShowingMenu, dropdownVisible }) => isShowingMenu ? (dropdownVisible ? '0' : '100vh') : '0'};
  opacity: ${({ isShowingMenu }) => isShowingMenu ? '0.25' : '0'};
  overflow: visible;
`

const SearchBackgroundWithOpacity = styled(BaseBackgroundWithOpacity) <{ isSearchExpanded: boolean, isShowingMenu: boolean }>`
  top: ${({ isSearchExpanded }) => isSearchExpanded ? '3px' : '0px'};
  min-height: ${({ isShowingMenu, isSearchExpanded }) => isShowingMenu ? '0' : (isSearchExpanded ? '100vh' : '0')};
  max-height: ${({ isShowingMenu, isSearchExpanded }) => isShowingMenu ? '0' : (isSearchExpanded ? '100vh' : '0')};
  opacity: ${({ isSearchExpanded }) => isSearchExpanded ? '0.25' : '0'};
  overflow: visible;
  z-index: 1;
`

const NavbarMobile = (props: NavbarProps) => {
  const [showMenu, setShowMenu] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [searchExpanded, setSearchExpanded] = useState(false)
  const { t: translate } = useTranslation('header')
  const { searchStore } = props

  const togleMenu = () => {
    setShowMenu(!showMenu)
    setSearchExpanded(false)
  }

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  const toggleSearchExpanded = () => {
    setSearchExpanded(!searchExpanded)
    setShowMenu(false)
    setDropdownVisible(false)

    try {
      setTimeout(() => {
        document.getElementById('header-search-input').focus()
      }, 200)
    } catch (e) {
      if(process.env.NODE_ENV !== 'production') {
        console.log(e)
      }
    }
  }

  return (
    <>
      <Wrapper data-testid="navbar-mobile-wrapper">
        <Content data-testid="content">
          <MenuButton data-testid="menu-button">
            {!showMenu
              ? <HamburgerMenuIcon onClick={() => togleMenu()} data-testid="hamburguer-menu-icon" aria-label={props.isEnVersion ? 'Open menu' : 'Abrir menu'} />
              : <CloseIcon onClick={() => togleMenu()} data-testid="close-menu-icon" aria-label={props.isEnVersion ? 'Close menu' : 'Fechar menu'} />
            }
          </MenuButton>

          <HomeLink href="/" data-testid="home-link" aria-label={props.isEnVersion ? 'Go back to home page' : 'Voltar para a página inicial'}>
            <SmallLogoIcon />
          </HomeLink>

          {!props.isEnVersion &&
            <SearchIconWrapper onClick={() => toggleSearchExpanded()} data-testid="search-icon-wrapper">
              {searchExpanded
                ? <CloseIcon title="Fechar" />
                : <SearchIcon title="Procurar" />
              }
            </SearchIconWrapper>
          }

          <BackgroundWithOpacity
            isShowingMenu={showMenu}
            dropdownVisible={dropdownVisible}
            isEnVersion={props.isEnVersion}
            onClick={() => togleMenu()}
            data-testid="background-w-opacity"
          />
        </Content>
      </Wrapper>

      <StyledMenu
        isOpen={showMenu && !searchExpanded}
        isEnVersion={props.isEnVersion}
        dropdownVisible={dropdownVisible}
        data-testid="styled-menu"
      >
        {props.isEnVersion
          ? <>
            <ItemMenu href="/about-us" data-testid="en-item-menu-1">{translate('about-us').toUpperCase()}</ItemMenu>
            <ItemMenu href="/" data-testid="en-item-menu-2">{translate('insights').toUpperCase()}</ItemMenu>
            <ItemMenu href="/pillow" data-testid="en-item-menu-3">{translate('pillow').toUpperCase()}</ItemMenu>
          </>
          : <>
            <DropdownMobileTitle onClick={() => toggleDropdown()} data-testid="dropdown-title">
              {translate('sleep').toUpperCase()}
              <IconWrapper reverse={dropdownVisible} >
                <DropdownIcon data-testid="dropdown-icon" />
              </IconWrapper>
            </DropdownMobileTitle>

            {dropdownVisible && <DropdownMobile data-testid="dropdown">
              {props.dropdownItems.map((item, index) =>
                <a href={item.path} key={item.key + index} data-testid={`dropdown-link${index}`}>{translate(item.key).toUpperCase()}</a>
              )}
            </DropdownMobile>}

            {props.menuItems.map((item, index) =>
              <ItemMenu href={item.path} key={item.key + index} data-testid={`menu-${index}`}>
                {translate(item.key).toUpperCase()}
              </ItemMenu>
            )}
          </>
        }

        {props.isEnVersion
          ? <ButtonMenu href="/buy" dropdownVisible={dropdownVisible} data-testid="pillow-button">
            {translate('buy-here')}
          </ButtonMenu>
          : <></>
        }
      </StyledMenu>

      <SearchStyledMenu
        isSearchExpanded={searchExpanded && !showMenu}
        data-testid="search-styled-menu"
      >
        <SearchWrapper data-testid="search-input-wrapper">
          <SearchTermsInput
            expanded={searchExpanded}
            disabled={!searchExpanded}
            searchStore={searchStore}
          />
        </SearchWrapper>
        <SearchBackgroundWithOpacity
            isShowingMenu={showMenu}
            isSearchExpanded={searchExpanded}
            onClick={() => setSearchExpanded(false)}
            data-testid="search-bg-w-opacity"
          />
      </SearchStyledMenu>
    </>
  )
}

export default NavbarMobile