import { BaseService } from './BaseService'
import {
  IGetCarouselResponse,
  IGetCategoryResponse,
  IGetPillowResponse,
  IGetPostResponse,
  IGetPostWithMetaResponse,
  IPersonoInMediaResponse,
  IPillowData,
  IPostDataWithMeta,
  IPressReleaseResponse,
  IPressReleaseResponseWithMeta,
  ISearchTagsResponse,
  ITagsData,
  ITagsGroups
} from './interfaces/WordpressServiceDtos'

interface IQuery {
  [key: string]: any
}

export interface IPostRequestParams {
  offset?: number,
  perPage?: number,
  categoryId?: number,
  slug?: string,
  tag?: number,
  id?: number,
  pageNumber?: number,
  search?: string
}

class WordpressPersonoService extends BaseService {
  private language
  protected environments = {
    baseUrl: process.env.WORDPRESS_PERSONO_BASEURL,
    baseTagsUrl: process.env.WORDPRESS_PERSONO_BASEURL_TAGS,
    bearerToken: null,
  }

  constructor(language: string = 'pt') {
    super()
    this.language = language
  }

  private getWithLang<T>(path: string, query?: IQuery): Promise<T> {
    const params = {
      lang: this.language,
      ...query,
    }
    return this.get<T>(path, { params })
  }

  private getTagsWithLang<T>(path: string, query?: IQuery): Promise<T> {
    const params = {
      lang: this.language,
      ...query,
    }
    return this.getTags<T>(path, { params })
  }

  private formatPost(res: IGetPostWithMetaResponse): IPostDataWithMeta[] {
    return res.data.map(r => ({
      data: r.data,
      yoast_meta: r.yoast_meta,
      yoast_json_ld: r.yoast_json_ld,
      tags: r.tags
    }))
  }

  async getCarouselPosts() {
    const response = await this.getWithLang<IGetCarouselResponse>('/carousel')
    return response.data
  }

  async getPosts(params: IPostRequestParams) {
    const response = await this.getWithLang<IGetPostResponse>('/posts', {
      per_page: params.perPage,
      offset: params.offset,
      slug: params.slug,
      categories: params.categoryId,
      tags: params.tag,
      id: params.id,
      page: params.pageNumber,
      search: params.search,
      _fields: 'title,slug,date,data,id'
    })

    return response
  }

  async getPillows() : Promise<IPillowData[]>{
    const response = await this.getWithLang<IGetPillowResponse>('/travesseiro', {
      per_page: 100,
      _fields: 'data,content,title,id',
    })
    
    return response.data
  }

  async getPostBySlugAndCategory(slug?: string | string[], category?: number) {
    const response = await this.getWithLang<IGetPostWithMetaResponse>('/posts', {
      slug,
      categories: category,
    })
    return this.formatPost(response)
  }

  async getAllPosts() {
    const response = await this.getWithLang<IGetPostResponse>('/posts', {
      _fields: 'title,slug,date,data'
    })
    return response.data.map(r => r.data)
  }

  async searchPostsByTerm(term: string, params?: IPostRequestParams) {
    const response = await this.getWithLang<IGetPostResponse>('/posts', {
      _fields: 'title,slug,date,data,id',
      per_page: params?.perPage ?? 100,
      page: params?.pageNumber ?? 1,
      search: term,
      offset: params?.offset ?? 0,
    })

    return response
  }

  async searchTagsByTerm(term: string) {
    const response = await this.getWithLang<ISearchTagsResponse>('/tags', {
      _fields: 'id,name,slug,count',
      per_page: 100,
      search: term,
    })

    return response.data
  }

  async getTag(tag: string) {
    const response = await this.getWithLang<IGetCategoryResponse>('/tags', { slug: tag })
    return response
  }

  async getNumberOfWPPostsByTag(tag: number) {
    const response = await this.getWithLang<IGetPostResponse>(`/posts?tags=${tag}`)
    return response.headers
  }

  async getAllCategories() {
    const response = await this.getWithLang<IGetCategoryResponse>('/categories', { per_page: 100 })
    return response.data
  }

  async getCategory(slug: string) {
    const response = await this.getWithLang<IGetCategoryResponse>('/categories', {
      slug,
      _fields: 'id, name, slug, count'
    })

    return response.data
  }

  async getAllPressReleases() {
    const response = await this.getWithLang<IPressReleaseResponse>('/pressrelease', {
      _fields: 'title, slug, data, content, date'
    })
    return response.data.map(r => r.data)
  }

  async getPressReleaseBySlug(slug: string) {
    const response = await this.getWithLang<IPressReleaseResponseWithMeta>('/pressrelease', { slug })
    return response.data.map(r => ({
      data: r.data,
      yoast_meta: r.yoast_meta,
      yoast_json_ld: r.yoast_json_ld
    }))
  }

  async getAllMediaPosts() {
    const response = await this.getWithLang<IPersonoInMediaResponse>('/personomedia', {
      _fields: 'title, slug, data, content, date'
    })
    return response.data.map(r => r.data)
  }

  async getHighlightTags(language: string): Promise<ITagsGroups[]> {
    const response = await this.getTagsWithLang<ITagsData>('groups', { fields: 'all' })
    if (language == 'en') {
      return response.data.filter(e => e.label == 'Tags Ingles')
    } else {
      return response.data.filter(e => e.label == 'Tags Portugues')
    }
  }

  async getAllTags(): Promise<ITagsGroups[]> {
    const response = await this.getTagsWithLang<ITagsData>('groups', { fields: 'all' })
    return response.data
  }
}

export default WordpressPersonoService
