import styled from 'styled-components'

const LoaderWrapper = styled.div`
  width: 100%;
  min-height: 30px;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
`

const LoaderItem = styled.div<{delay: string, marginBottom: string}>`
  width: max-content;
  min-width: 5px;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  animation: heart-beat 700ms linear alternate infinite;
  animation-delay: ${({delay}) => `${delay}ms`};
  will-change: font-size;
  margin: 10px 2px;
  font-size: 120%;
  margin-bottom: ${({marginBottom}) => `${marginBottom}px`};

  @keyframes heart-beat {
    0% {
      font-size: 14px;
    }

    25% {
      font-size: 15px;
    }

    50% {
     
      font-size: 16px;
    }
  
    75% {
   
      font-size: 20px;
    }

    100% {
      font-size: 22px;
    }
  }
`
export const SearchLoader = () => (
  <LoaderWrapper data-testid="loader-wrapper">
    <LoaderItem delay="0" marginBottom="0" data-testid="loader-item-1"> Z </LoaderItem>
    <LoaderItem delay="150" marginBottom="14" data-testid="loader-item-2"> Z </LoaderItem>
    <LoaderItem delay="300" marginBottom="28" data-testid="loader-item-3"> Z </LoaderItem>
  </LoaderWrapper>
)