import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'
import { LeadsInput } from '../../molecules/LeadsInput'
import LeadStore from '../../stores/LeadStore/LeadStore'
import { ILanguageVersionInterface } from '../../templates/LanguageInterface'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;
  height: 286px;
  margin: 0;
  z-index: 1;

  background-color: #000;

  ${media.tabMUp} {
    height: 330px;
  }

  ${media.deskGUp} {
    height: 450px;
  }
`

const Div = styled.div`
  width: 90%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.deskG} {
    padding: 0;
  }

  ${media.deskGUp} {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
  }
`

const Text = styled.span<{isEnVersion: boolean}>`
  max-width: 300px;
  margin-bottom: 16px;
  font-size: ${props => props.isEnVersion ? '32px' : '26px'};
  line-height: ${props => props.isEnVersion ? '42px' : '32px'};
  font-weight: bold;
  color: #FFF;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.3);

  ${media.tabMUp} {
    max-width: ${props => props.isEnVersion ? '500px' : '440px'};
    margin-right: 50px;
    font-size: ${props => props.isEnVersion ? '50px' : '40px'};
    line-height: ${props => props.isEnVersion ? '72px' : '52px'};
  }
  
  ${media.deskGUp} {
    margin-bottom: 0;
    margin-right: 32px;
    font-size: ${props => props.isEnVersion ? '75px' : '56px'};
    line-height: ${props => props.isEnVersion ? '90px' : '67px'};
  }

  ${media.deskGG} {
    width: 40%;
  }
`

const store = new LeadStore()

export const Leads = (props: ILanguageVersionInterface) => {
  const {t: translate} = useTranslation('lead')

  return (
    <Container>
      <Div>
        <Text isEnVersion={props.language == 'en'}>{translate('leads-label')}</Text>
        <LeadsInput shouldHaveLedsText={true} store={store} fromModal={false}/>
      </Div>
    </Container>
  )
}