import moment from 'moment'
import 'moment/locale/pt-br'
import { InsightsPathsEnum } from '../../public/static/insights-paths.enum'
import { TermTypes } from '../components/stores/SearchStore/SearchStore'

export const generatePostLink = (categorySlug: string, postSlug: string) => {
  return `/insights/${categorySlug}/${postSlug}`
}

export const generatePostTagLink = (slug: string) => {
  return `/insights/tags/${slug}`
}

export const generateSearchLink = (term: string | string[], pageNumber?: number) => {
  if (pageNumber != null) return `/insights/search?page=${pageNumber}&term=${term}`
  return `/insights/search?page=1&term=${term}`
}

export const generateSearchResultLink = (type: TermTypes, slug: string, category?: string) => {
  if(type == TermTypes.post) {
    return generatePostLink(category, slug)
  }
  return generatePostTagLink(slug)
}

export const generateNextPageLink = (pageNumber: number, slug: string | string[], currentURL: InsightsPathsEnum) => {
  if (currentURL == InsightsPathsEnum.DEFAULT) {
    if (pageNumber == 1) return `/`
    return `/insights/${pageNumber}`
  }

  if (currentURL == InsightsPathsEnum.TAGS) {
    if (pageNumber == 1) return `/insights/tags/${slug}`
    return `/insights/tags/${slug}/${pageNumber}`
  }

  if (currentURL == InsightsPathsEnum.CATEGORY) {
    if (pageNumber == 1) return `/insights/category/${slug}`
    return `/insights/category/${slug}/${pageNumber}`
  }

  if (currentURL == InsightsPathsEnum.SEARCH) {
    return generateSearchLink(slug, pageNumber)
  }
}

export const formatCreationDate = (creationDate: Date, language: string) => {
  if (language == 'en') {
    moment.locale('en')
    return moment(creationDate).format('MMMM DD, YYYY').toString()
  } else {
    moment.locale('pt-br')
    return moment(creationDate).format('DD [de] MMMM, YYYY').toString()
  }
}