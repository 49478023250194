import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { breakpoints, media } from '../../../styles/breakpoints'
import { colors } from '../../../styles/colors'
import { PathKeyItems } from '../../organisms/Header'
import { DropdownIcon } from '../icons/DropdownIcon'
import { DropdownItem } from '../NavItem'

const Dropdown = styled.div`
  font: 14px Lato, sans-serif;
  height: 76px;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 16px;
  background-color: #fff;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  text-decoration: none;
  width: 100%;

  ${media.tabMUp} {
    position: relative;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    height: 90px;
    width: 90px;
    margin: 3px 0 0 0;
    padding: 25px 0 0 0;
  }

  &:hover{
    .content {
      display: block;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 55px;
    left: 0px;
    right: 0;
    margin: auto;
    width: 10px;
    opacity: 0;
    height: 3px;
    background: ${colors.irisBlue};
    border-radius: 10px;
    transition: all 200ms ease-in-out;
  }

  ${css`
    &:hover::before {
      opacity: 1;
      transform: scaleX(6);
      transition: all 200ms ease-in-out;
    }
  `};
`

const DropdownContent = styled.div<{clicked: boolean}>`
  display: ${({clicked}) => clicked ? 'block' : 'none'};
  position: absolute;
  background-color: #FFF;
  top: 70px;
  width: 310px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 10px;
`

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 20px 0;
`

const TitleWrapper = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 32px;
  color: ${colors.gray13};
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 10px 5px 10px;
  align-items: center;
  justify-content: space-evenly;
  width: 70px;

  ${media.tabMUp} {
    font-size: 14px;
  }
`

interface IDropdownMenu {
  title: string
  dropdownItems: PathKeyItems[]
}

const DropdownMenu = (props: IDropdownMenu) => {
  const router = useRouter()
  const { t: translate } = useTranslation('header')
  const [dropdownClicked, setClicked] = useState(false)

  const handleClick = () => {
    if(window.innerWidth < breakpoints.tabM) { 
      setClicked(!dropdownClicked)
    }
  }

  return (
    <Dropdown>
      <TitleWrapper onClick={() => handleClick()} data-testid="title">
        {props.title} 
        <DropdownIcon /> 
      </TitleWrapper>
      <DropdownContent className="content" clicked={dropdownClicked} data-testid="dropdown">
        {props.dropdownItems.map((item, index) =>
          <ItemWrapper key={item.key+index} data-testid={`item-${index}`}>
            <DropdownItem href={item.path} isActive={router.asPath.includes(item.path)}>
              {translate(item.key)}
            </DropdownItem>
          </ItemWrapper>
        )}
      </DropdownContent>
    </Dropdown>
  )
}

export default DropdownMenu